<script>
import { required, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import Layout from '../../layouts/main.vue'
import Swal from 'sweetalert2'
import CryptoJS from "crypto-js"
import PageHeader from '@/components/page-header'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      data: {
        faculty: ''
      },
      filiere: {},
      students: {},
      classes: [],
      programmes: [],
      studentOptions: [],
      facultiesOptions: [],
      admitions: [],
      filteredAdmitions: [],
      currentTime: '',
      submited: false,
      title: 'Gestion Presences',
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },
      items: [
        {
          text: 'Etudiants',
          href: '/new-student'
        },
        {
          text: 'Presences',
          active: true
        }
      ]
    }
  },
  validations: {
    data: {
      course: {
        required: helpers.withMessage('Veuillez selectionnez un cours', required)
      },
      date: {
        required: helpers.withMessage('Veuillez selectionnez une date', required)
      },
      heure: {
        required: helpers.withMessage('Veuillez selectionnez une heure', required)
      }

    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    flatPickr,
  },
  mounted() {
    this.decryptFiliere()
    this.getInDayScheduledCourses()
    this.getAdmitions()
  },

  methods: {
    getAdmitions() {
      const self = this
      this.$store
        .dispatch('getRequest', {
          route: `api/getStudents/${this.data.filiere_id}`,
          data: this.data
        })
        .then(
          function (response) {
            self.admitions = response.data.map(admition => {
              return {
                id: admition.student?.id,
                matricule: admition.student?.matricule,
                nom: admition.student?.nom,
                prenom: admition.student?.prenom,
                checkboxValue: false
              }
            });
            console.log(self.admitions);
          },
          function (error) {
            console.log(error)
          }
        )
    },


    submit() {
      this.v$.data.$touch()
      const students_data = this.admitions.map(student => {
        return {
          student: student.id,
          cours: this.data.course,
          date: this.data.date,
          heure: this.data.heure,
          checkboxValue: student.checkboxValue,
        }
      });
      
      this.$store.dispatch('postRequest', {
          route: '/api/presence',
          data: students_data
        }).then(
          function (response) {
            Swal.fire(response.data.message,'La liste de presence a été enregistré!','success')
          },
          function (error) {
            console.log(error)
            Swal.fire({
              title: 'Oops...',
              text: 'Il y a eu une erreur!',
              icon: 'error',
              confirmButtonClass: 'btn btn-primary w-xs mt-2',
              buttonsStyling: false,
              showCloseButton: true
            })
          }
        )
    },

    decryptFiliere() {
      const encryptedMessage1 = localStorage.getItem("dataF");
      if (!encryptedMessage1) {
        console.error('Aucune donnée trouvée pour "dataF" dans localStorage.');
        return;
      }

      const encryptionKey1 = "Slim#9065";

      try {
        const decrypted1 = CryptoJS.AES.decrypt(encryptedMessage1, encryptionKey1);
        const decryptedMessage1 = decrypted1.toString(CryptoJS.enc.Utf8);

        if (!decryptedMessage1) {
          console.error("Erreur lors du décryptage : le message décrypté est vide ou invalide.");
          return;
        }

        this.filiere = JSON.parse(decryptedMessage1);
        this.data.filiere_id = this.filiere.id;


      } catch (error) {
        console.error("Erreur lors du décryptage des données :", error);
      }
    },
    getInDayScheduledCourses() {
      this.data.filiere_id = this.filiere.id;
      this.$store.dispatch("getRequest", {
        route: `api/getSeances/${this.data.filiere_id}`,
        data: this.data,
      })
        .then(response => {
          this.programmes = response.data.map(course => ({
            value: course.id,
            label: course.matiere?.nom_cours + " (" + course.jour + ':' + course.heure_debut + "-" + course.heure_fin + ")",
          }));

        })
        .catch(error => console.log(error));
    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-lg-4">
              <label for="fac" class="form-label">Cours</label>
              <Multiselect class="form-control" id="fac" v-model="data.course" :class="{
                'is-invalid':
                  (v$.data.course.$error && data.course) ||
                  (v$.data.course.$error && submited)
              }" :close-on-select="true" :searchable="true" :show-labels="false" :options="programmes" />
              <div v-for="(item, index) in v$.data.course.$errors" :key="index" class="invalid-feedback">
                <span v-if="
                  (item.$message && data.course) ||
                  (v$.data.course.$error && submited)
                ">{{ item.$message }}</span>
              </div>
            </div>
            <div class="col-lg-4">
              <label class="form-label">Date</label>
              <flatPickr v-model="data.date" placeholder="Date" class="form-control" />
              <div v-if="v$.data.date.$error" class="text-danger">
                {{ v$.data.date.$errors[0].$message }}
              </div>
            </div>
            <div class="col-lg-4">
              <label class="form-label">Heure</label>
              <flatPickr v-model="data.heure" :config="timePicker" placeholder="Heure" class="form-control" />
              <div v-if="v$.data.heure.$error" class="text-danger">
                {{ v$.data.heure.$errors[0].$message }}
              </div>
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead>
                <tr>
                  <th scope="col">matricule</th>

                  <th scope="col">Etudiant</th>

                  <th scope="col">Absence</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="student in admitions" :key="student.id">
                  <th scope="row">{{ student.matricule }}</th>

                  <td>{{ student.nom }} {{ student.prenom }}</td>

                  <td>
                    <!-- <div class="checkbox checkbox-danger error-checkbox"> -->
                        <input type="checkbox" v-model="student.checkboxValue" >
                    <!-- </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-lg-12 mt-3 d-flex justify-content-end">
            <button @click="submit()" type="submit" class="btn btn-success">
              <i class="mdi mdi-arrow-down-bold-box-outline"></i>
              Enregirer
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>